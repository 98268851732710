// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-archive-js": () => import("./../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-curriculums-js": () => import("./../src/templates/curriculums.js" /* webpackChunkName: "component---src-templates-curriculums-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-post-amp-js": () => import("./../src/templates/blog-post.amp.js" /* webpackChunkName: "component---src-templates-blog-post-amp-js" */),
  "component---src-templates-posts-index-js": () => import("./../src/templates/posts/index.js" /* webpackChunkName: "component---src-templates-posts-index-js" */),
  "component---src-templates-categories-js": () => import("./../src/templates/categories.js" /* webpackChunkName: "component---src-templates-categories-js" */),
  "component---src-templates-tags-js": () => import("./../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-curriculums-index-js": () => import("./../src/templates/curriculums/index.js" /* webpackChunkName: "component---src-templates-curriculums-index-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-404-js": () => import("./../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */)
}

